import React from 'react';
import { graphql } from 'gatsby';

import { Header, HeadlineTP, BodyTP } from '../styles';
import Layout from '../components/Layout';
import MetaData from '../components/meta/MetaData';

const Privacy = ({ data }) => {
	const privacy = data.allGooglePrivacySheet.edges;

	const metaDescription = data.allGoogleContentNewSheet.edges.find(
		({ node: { position } }) => position === 'landing-meta-description'
	).node.text;

	return (
		<Layout>
			<MetaData
				title="Envel - Privacy"
				description={`Privacy Policy of Envel - ${metaDescription}`}
				image="images/social/social.png"
			/>

			<Header center>Privacy</Header>
			{privacy.map(({ node: { title, content } }) => (
				<div key={title}>
					<HeadlineTP dangerouslySetInnerHTML={{ __html: title }} />
					<BodyTP dangerouslySetInnerHTML={{ __html: content }} />
				</div>
			))}
		</Layout>
	);
};

export default Privacy;

export const privacyQuery = graphql`
	query {
		allGooglePrivacySheet {
			edges {
				node {
					title
					content
				}
			}
		}
		allGoogleContentNewSheet {
			edges {
				node {
					position
					text
				}
			}
		}
	}
`;
